import React, { Component, Suspense } from 'react'
import './Content.css';
import router from '../router';
import { Routes, Route, Router } from "react-router-dom";

export default class Content extends Component {
  render() {
    // let Loader = (
    //   <div class="spinner-border" role="status">
    //   <span class="sr-only">Loading...</span>
    //   </div>
    // )
    return (
        <Suspense>
          <Routes>
          {
            router.map((page, idx)=>(
              <Route path={page.path} key={page.key} element={<page.element />} />
            ))
          }
          </Routes>
        </Suspense>
    )
  }
}
