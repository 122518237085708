import React, { Component } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { BsFillPersonFill, BsFillHouseDoorFill, BsFillPersonPlusFill, BsFillChatLeftDotsFill } from "react-icons/bs";

export default class Topbar extends Component {

    componentDidMount(){
        this.updateNavPosition()
    }

    updateNavPosition(){
        const offsetY = window.scrollY
        if(document.querySelector('#mainNav')){
            
            if (offsetY >= 39) {
                document.querySelector('#mainNav').classList.add('fixed-top')
            } else {
                document.querySelector('#mainNav').classList.remove('fixed-top')
            }
        }
        setTimeout(() => {
            this.updateNavPosition()
        }, 10);
    }

    render() {
        return (
            <>
                <Navbar bg='dark' expand="lg" id="mainNav" >
                    <Container>
                        <Navbar.Brand className="text-light">CreamMie</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                {/* <Link to="/" className="text-light nav-link">Home</Link>
                                <Link to="/contact" className="text-light nav-link">Contact</Link> */}
                                <Nav.Link as={Link} to="/" className="text-light nav-link">Home <BsFillHouseDoorFill /></Nav.Link>
                                <Nav.Link as={Link} to="/contact" className="text-light nav-link">Contact <BsFillChatLeftDotsFill /></Nav.Link>
                                <NavDropdown 
                                title={
                                    <span className="text-light">About</span>
                                } 
                                id="basic-nav-dropdown" 
                                >
                                    <NavDropdown.Item href="#action/3.1" className="text-dark">Personal Record</NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.2" className="text-dark">Educational Record</NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.3" className="text-dark">Skill</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="#action/3.4" className="text-dark">Performance</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </>
        )
    }
}
