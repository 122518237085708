import React, { Component } from 'react'
import Main from './Views' 
import { BrowserRouter } from 'react-router-dom'

export default class App extends Component {
  render() {
    if (document.querySelector('body').classList.contains('overflow-hidden')) {
      document.querySelector('body').classList.remove('overflow-hidden')
    }
    return (
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    )
  }
}
