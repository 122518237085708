import React, { Component } from 'react'
import Topbar from './Topbar'
import Content from './Content'

export default class Main extends Component {
  render() {
    return (
      <>
        <Topbar />
        <Content />
      </>
    )
  }
}
